import SideMeny from './SideMeny';
import Countdown from './Countdown';
import { Typography, CardMedia, Box, Paper } from '@mui/material';
import Divider from '@mui/material/Divider';


export default function Header() {
    const weddingDate = 'September 6, 2025 13:00:00';
    return (
        <section className="main-section">
            <SideMeny />
            <Box sx={{ height: '50vh', overflow: 'hidden' }}>
                <CardMedia 
                    component="img"
                    alt="Torjus & Victoria"
                    image="/assets/forside_v&t-min.png"
                    sx={{minHeight: '100%', minWidth: '100%', objectFit: 'cover', maxWidth: '1300px'}}
                />
            </Box>
            <Box>
                <Typography variant="h1" component="h1" sx={{ textAlign: 'center', fontFamily: 'Luminari', fontSize: 'calc(40px + 6vmin)', paddingBottom: 3 }}>
                    Torjus & Victoria
                </Typography>
                <Typography variant="h4" component="h1" sx={{ textAlign: 'center', fontFamily: 'luminari', fontSize: 'calc(10px + 6vmin)', paddingBottom: 3 }}>
                    Vi gifter oss!
                </Typography>
                {<Typography variant="h4" component="h1" sx={{ textAlign: 'center', fontFamily: 'luminari', fontSize: 'calc(9px + 3vmin)', paddingBottom: 3 }}>
                    6. September 2025
                </Typography> }
                <Countdown targetDate={weddingDate} />
            </Box>

            <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', padding: '10px', minWidth: '370px', justifyContent: 'center', alignContent: 'center'}}>    
                <Paper elevation={4} sx={{ padding: '5px', minWidth: '200px', minHeight: '100px', margin: '5px' }}>
                    {/* Hvor? */}
                    <Typography variant="h6" component="h1" sx={{ textAlign: 'center', fontFamily: 'luminari', fontSize: '22px' }}>
                        Hvor?
                    </Typography>
                    <Divider />
                    <Typography variant="h6" component="h1" sx={{ textAlign: 'center', fontFamily: 'luminari', fontSize: '18px', paddingTop: 0.5 }}>
                        Eckbo Selskapslokaler
                    </Typography>
                    <Typography variant="h6" component="h1" sx={{ textAlign: 'center', fontFamily: 'luminari', fontSize: '18px' }}>
                        Ris
                    </Typography>
                </Paper>
            
                <Paper elevation={4} sx={{ padding: '5px', minWidth: '200px', minHeight: '100px', margin: '5px' }}>
                    {/* Når? */}
                    <Typography variant="h6" component="h1" sx={{ textAlign: 'center', fontFamily: 'luminari', fontSize: '22px' }}>
                        Når?
                    </Typography>
                    <Divider />
                    {/* <Typography variant="h6" component="h1" sx={{ textAlign: 'center', fontFamily: 'luminari', fontSize: '18px', paddingTop: 2 }}>
                        2025
                    </Typography> */}
                    <Typography variant="h6" component="h1" sx={{ textAlign: 'center', fontFamily: 'luminari', fontSize: '18px', paddingTop: 0.5 }}>
                        6. september
                    </Typography>
                    <Typography variant="h6" component="h1" sx={{ textAlign: 'center', fontFamily: 'luminari', fontSize: '18px' }}>
                        13:00
                    </Typography>
                </Paper>
            
                <Paper elevation={4} sx={{ padding: '5px', minWidth: '200px', minHeight: '100px', margin: '5px' }}>
                    {/* Hva? */}
                    <Typography variant="h6" component="h1" sx={{ textAlign: 'center', fontFamily: 'luminari', fontSize: '22px' }}>
                        Hvem?
                    </Typography>
                    <Divider />
                    <Typography variant="h6" component="h1" sx={{ textAlign: 'center', fontFamily: 'luminari', fontSize: '18px', paddingTop: 0.5 }}>
                        Familie
                    </Typography>
                    <Typography variant="h6" component="h1" sx={{ textAlign: 'center', fontFamily: 'luminari', fontSize: '18px' }}>
                        Venner
                    </Typography>
                </Paper>
                    
            </Box>
        </section>
    );
}