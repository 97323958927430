import { Box, Typography, useMediaQuery } from '@mui/material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import EmailIcon from '@mui/icons-material/Email';
import TagIcon from '@mui/icons-material/Tag';

export default function Footer() {
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <Box display="flex" flexDirection='column' justifyContent="space-between" alignItems="center" position="relative" bottom={0} bgcolor="black" color="white" textAlign="center" p={2}>
            <Box
                display="flex"
                justifyContent="space-around"
                flexDirection={isMobile ? 'column' : 'row'}
                alignItems="center"
                textAlign="center"
                p={2}
            >
                <Box display="flex" flexDirection="column" alignItems="center" minWidth='200px' flex={1} m={2}>
                    <SupportAgentIcon sx={{marginRight: 2}}/> 
                    <Typography variant="body1">
                        Om du har tilbakemeldinger eller trenger hjelp, ta kontakt med administrator Julian <a href='mailto:julian.aaserud@gmail.com'>her</a>
                    </Typography>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center" minWidth='200px' flex={1} m={2}>
                    <EmailIcon sx={{marginRight: 2}}/> 
                    <Typography variant="body1">
                        Om du har noen spørsmål som du ikke har fått svar på, ta kontakt med brudeparet <a href='mailto:torjus.klafstad@gmail.com'>her</a>
                    </Typography>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center" minWidth='200px' flex={1} m={2}>
                    <TagIcon sx={{marginRight: 2}}/> 
                    <Typography variant="body1">
                        Dersom dere tar bilder på bryllupsdagen, bruk gjerne hashtaggen #torjusogvictoria på sosiale medier.
                    </Typography>
                </Box>
            </Box>
            <Typography variant="body2">© 2025 Torjus & Victoria.</Typography>
        </Box>
    );
};