import { Button, TextField, Checkbox, FormControlLabel, Box } from '@mui/material';
import { FormControl, RadioGroup, Radio, Alert } from '@mui/material';
import { useState } from 'react';

export default function Rsvp() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [plusOne, setPlusOne] = useState(false);
    const [plusOneName, setPlusOneName] = useState('');
    const [dietaryRestrictions, setDietaryRestrictions] = useState('');
    const [comment, setComment] = useState('');
    const [attending, setAttending] = useState('yes');
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validate inputs here
        if (!name || !email) {
            console.log('Invalid input');
            setShowSuccessAlert(false);
            setShowErrorAlert(true);
            return;
        }

        // Prepare JSON for HTTP request
        const data = {
            navn: name,
            folge: plusOneName,
            epost: email,
            deltakelse: attending,
            allergier: dietaryRestrictions,
            sporsmaal: comment
        };

        try {
            // Send data to the server
            const response = await fetch('/rsvp-submit', { 
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            });
            if (!response.ok) {
                console.error('Failed to submit RSVP');
                setShowSuccessAlert(false);
                setShowErrorAlert(true);
                return;
            } else {    
                console.log('RSVP submitted successfully');
                // Reset form fields
                setName('');
                setEmail('');
                setPlusOne(false);
                setPlusOneName('');
                setDietaryRestrictions('');
                setComment('');
                setAttending('yes');
            
                setShowSuccessAlert(true);
                setShowErrorAlert(false);
            }
        }
        catch (error) {
            console.error('Failed to submit RSVP', error);

            setShowSuccessAlert(false);
            setShowErrorAlert(true);
            return;
        }

       
        console.log(JSON.stringify(data));
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 2, margin: 4, maxWidth: 600 }}>
                
                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: 2, width: '100%' }}>
                    <TextField label="Navn" value={name} onChange={(e) => setName(e.target.value)} fullWidth />
                    <TextField label="E-post" value={email} onChange={(e) => setEmail(e.target.value)} fullWidth />
                </Box>
                <FormControlLabel
                    control={<Checkbox checked={plusOne} onChange={(e) => setPlusOne(e.target.checked)} />}
                    label="Følge"
                />
                {plusOne && (
                    <TextField
                        label="Navn på følge"
                        value={plusOneName}
                        onChange={(e) => setPlusOneName(e.target.value)}
                        fullWidth
                    />
                )}
                <TextField label="Allergier eller restriksjoner" value={dietaryRestrictions} onChange={(e) => setDietaryRestrictions(e.target.value)} fullWidth />
                <TextField
                    label="Kommentar"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    multiline
                    rows={4}
                    fullWidth
                />
                <FormControl component="fieldset">
                    <RadioGroup
                        value={attending}
                        onChange={(e) => setAttending(e.target.value)}
                        row
                    >
                        <FormControlLabel value="yes" control={<Radio />} label="Jeg kommer" />
                        <FormControlLabel value="no" control={<Radio />} label="Jeg kommer ikke" />
                    </RadioGroup>
                </FormControl>
                <Button type="submit" variant="contained" sx={{width: '100%'}}>
                    Send inn
                </Button>
                {showSuccessAlert && (
                    <Alert variant="outlined" severity="success" onClose={() => setShowSuccessAlert(false)}>
                        RSVP Sendt inn!
                    </Alert>
                )}
                {showErrorAlert && (
                    <Alert variant="outlined" severity="error" onClose={() => setShowErrorAlert(false)}>
                        Noe gikk galt. Send inn en mail og si ifra.
                    </Alert>
                )}
            </Box>
        </form>
    );
}