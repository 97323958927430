import React, { useState } from 'react';
import CustomizedTimeline from './Timeline.js';
import Header from './Header.js';
import SectionNormal from './SectionNormal.js';
// import { Typography } from '@mui/material';
import Rsvp from './Rsvp.js';
import Wishlist from './Wishlist.js';
import Footer from './Footer.js';
import Spørsmål from './Spørsmål.js';
import PlaceIcon from '@mui/icons-material/Place';
import { Button, CardContent, Card, Box, CardMedia } from '@mui/material';

export default function Root() {
    const [displayWishlist, setDisplayWishlist] = useState(false);

    return (
        <div className="App" id='home'>
            <Header />

            <section className="section"> 
                <SectionNormal heading="Velkommen til vårt bryllup!" text="Vi gleder oss til å samle familie og venner i en feiring av kjærligheten." image="/assets/ekstramedring-min.png" />
            </section>

            <section className="section"> 
                <SectionNormal heading="Lokalet - Eckbo Selskapslokaler" 
                text="Dere inviteres til å være med på vielse, middag og fest i Eckbo Selskapslokaler på Ris i Oslo. " 
                image="https://www.eventbyraaet.no/wp-content/uploads/2017/11/Villa-Eckbo-selskapslokaler.jpg" 
                includeButton={true} buttonText={["Veien til lokalet", <PlaceIcon />]} 
                buttonLink="https://maps.app.goo.gl/vxPEDihBWStMmpbC9"/>
            </section>
            
            <div
                style={{
                    position: 'relative',
                    width: '100%',
                    maxWidth: '100vw',
                    overflow: 'hidden'
                }}
            >
                <img
                    src="/assets/banner_v&t-min.png"
                    alt="bilde"
                    style={{
                        width: '100%',
                        minHeight: '420px',
                        objectFit: 'cover',
                        objectPosition: '35% center',
                    }}
                />
            </div>

            
            <section className="section" id='program-section'> 
                <h2>Foreløpig program for dagen</h2>
                <CustomizedTimeline />
            </section>


            {/* <div style={{display: 'flex', justifyContent: 'center', maxWidth:'100vw', overflowX: 'clip', height:'auto'}}>

                <img src="/assets/ekstra2_v&t-min.png" alt="bilde" style={{maxWidth: '250px', height: 'auto', borderRadius: '50%', transform: 'translateX(140px) translateY(100px) rotate(-13deg)', zIndex: '1'}} />
                
                <img src="/assets/ekstra3_v&t-min.png" alt="bilde" style={{maxWidth: '250px', height: 'auto', borderRadius: '50%', transform: 'translateX(0px) translateY(-80px) rotate(0deg)', zIndex: '3'}} />

                <img src="/assets/ekstra4_v&t-min.png" alt="bilde" style={{maxWidth: '250px', height: 'auto', borderRadius: '50%', transform: 'translateX(-140px) translateY(120px) rotate(13deg)', zIndex: '2'}} />

            </div> */}

            <section className="section" id='qna-section'>

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 2, margin: 0, maxWidth: 600 }}>
                    <Card sx={{backgroundColor: 'background.default', maxWidth: '600px'}}>
                        <CardMedia
                            component="img"
                            image="/assets/ekstra2_v&t-min.png"
                            height="650px"
                        />
                        <CardContent>
                            <Box sx={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row', gap: 2, paddingBottom: 3 }}>
                                <h2>Spørsmål og Svar</h2>
                            </Box>  
                            <Spørsmål />
                        </CardContent>
                    </Card>
                </Box>

            </section>


            <section className="section" id='rsvp-section'>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 2, margin: 0, maxWidth: 600 }}>
                    <Card sx={{backgroundColor: 'background.default', maxWidth: '600px'}}>
                        <CardMedia
                            component="img"
                            image="/assets/tor&vic.jpg" 
                            fullWidth
                        />
                        <CardContent>
                            <Box sx={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row', gap: 2, paddingBottom: 3 }}>
                                <h2>RSVP</h2>
                            </Box>  
                            <Rsvp />
                        </CardContent>
                    </Card>
                </Box>
            </section>


            <section className="section" id='wishlist-section'>
                {displayWishlist ? <h2>Ønskeliste</h2> : <h2>Har du lyst til å se hva vi ønsker oss?</h2>}
                {!displayWishlist && <Button variant="contained" onClick={() => setDisplayWishlist(true)}>Se ønskeliste</Button>}
                {displayWishlist && <Wishlist />}
            </section>


            <Footer />
        </div>
    );
}
