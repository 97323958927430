import React, { useState, useEffect } from 'react';

export default function Countdown({ targetDate }) {
    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
  
    function calculateTimeRemaining() {
      const difference = +new Date(targetDate) - +new Date();
      let timeRemaining = {};
  
      if (difference > 0) {
        timeRemaining = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60)
        };
      }
      return timeRemaining;
    }
  
    useEffect(() => {
      const timer = setInterval(() => {
        setTimeRemaining(calculateTimeRemaining());
      }, 60000);
  
      return () => {
        clearInterval(timer);
      };
    });
  
    const { days, hours, minutes } = timeRemaining;

    if (  days === 0 && hours === 0 && minutes === 0) {
      return (
        <div className="countdown">
          <div className="countdown-timer">
            <div className="countdown-item">
              <h2>Vielsen har startet!</h2>
            </div>
          </div>
        </div>
      );
    }
  
    return (
      <div className="countdown">
        <div className="countdown-timer">
          <div className="countdown-item">
            <span>{days} dager, {hours} timer og {minutes} minutter.</span>
          </div>
        </div>
      </div>
    );
  }