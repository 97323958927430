import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Box } from '@mui/material';

export default function Spørsmål() {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 2, margin: 0, maxWidth: 600 }}>
            <Accordion sx={{width: '100%'}}>
                <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                <Typography>Hva har man på seg?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    Kleskoden er mørk dress / Kjole og hvitt. Det er også mulig å bruke bunad. 
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{width: '100%'}}>
                <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                <Typography>Kan jeg parkere der?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    Det går an å parkere over natten på veien utenfor lokalet. Lokalet er også i nærheten av Ris og Slemdal T-banestasjoner, så det er mulig å ta T-banen.
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{width: '100%'}}>
                <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                <Typography>Jeg vil gjerne holde tale!</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    Så fint! Ta kontakt med toastmaster Camilla på <a href='mailto:camilla_klafstad@hotmail.com'>camilla_klafstad@hotmail.com</a>,
                    så kan hun hjelpe deg. 
                    Merk at det er begrenset med tid, og dermed begrenset med taler.
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{width: '100%'}}>
                <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                <Typography>Blir det nattmat?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    Det blir pølser!
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{width: '100%'}}>
                <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                <Typography>Blir det åpen bar?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    ;) 
                </Typography>
                </AccordionDetails>
            </Accordion>
        
        </Box>
    );
}